const footerMenuList = [
  {
    id: 1,
    title: "Accesspoint",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "#",
        text: "About Us",
      },
      {
        id: 2,
        href: "#",
        text: "Our Team",
      },
      {
        id: 3,
        href: "#",
        text: "Contact Us",
      },
      {
        id: 4,
        href: "#",
        text: "Careers",
      },
      {
        id: 5,
        href: "#",
        text: "Blog",
      },
      {
        id: 6,
        href: "#",
        text: "FAQ",
      },
    ],
  },
  {
    id: 2,
    title: "Services",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "#",
        text: "Products",
      },
      {
        id: 2,
        href: "#",
        text: "Solutions",
      },
      {
        id: 3,
        href: "#",
        text: "Case Studies",
      },
      {
        id: 4,
        href: "#",
        text: "Resources",
      },
      {
        id: 5,
        href: "#",
        text: "Partners",
      },
    ],
  },
  {
    id: 3,
    title: "My Account",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "#",
        text: "News",
      },
      {
        id: 2,
        href: "#",
        text: "Testimonials",
      },
      {
        id: 3,
        href: "#",
        text: "Sitemap",
      },
      {
        id: 4,
        href: "#",
        text: "Accessiblity",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://www.twitter.com",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://www.discord.com",
    text: "discord",
  },
  {
    id: 4,
    href: "https://www.instagram.com",
    text: "instagram",
  },
  {
    id: 5,
    href: "https://www.t.me",
    text: "telegram",
  },
];

export { footerMenuList, socialIcons };
