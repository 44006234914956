import Head from "next/head";
import { getDynamicAITitle } from "lib/title-ai";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        {/* <link rel="icon" type="image/svg+xml" href="/favicon.svg" /> */}
        <link rel="icon" type="image/png" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: getDynamicAITitle(),
  keyword:
    "bitcoin, blockchain, crypto, crypto collectibles, crypto marketplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet",
  desc: "The world's first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.",
};

export default Meta;
