import { getDynamicAITitle } from "lib/title-ai";
import Image from "next/image";
import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";

const footer = () => {
  return <>
    {/* <!-- Footer --> */}

    <footer className="dark:bg-jakarta-900 page-footer bg-white">
      <div className="container">
        <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
          <div className="col-span-3 md:col-span-4">
            {/* <!-- Logo --> */}
            <Link href="#" className="mb-6 flex font-title">
              <Image
                src="/images/logo.svg"
                className="max-h-7 dark:hidden mr-1"
                alt="Accesspoint | "
                width={50}
                height={50}
              />
              <span>Accesspoint</span>
            </Link>
            <p className="dark:text-jakarta-300 mb-12">
              {getDynamicAITitle()}
            </p>

            {/* <!-- Socials --> */}
            <div className="flex space-x-5">
              {socialIcons.map((item) => {
                const { id, href, text } = item;
                return (
                  <Link
                    href={href}
                    key={id}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group cursor-pointer"
                    legacyBehavior>
                    <svg className="icon group-hover:fill-accent fill-jakarta-300 h-5 w-5 dark:group-hover:fill-white">
                      <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                    </svg>
                  </Link>
                );
              })}
            </div>
          </div>
          {footerMenuList.map((single) => (
            <div
              className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`}
              key={single.id}
            >
              <h3 className="font-display text-jakarta-700 mb-6 text-sm dark:text-white">
                {single.title}
              </h3>
              <ul className="dark:text-jakarta-300 flex flex-col space-y-1">
                {single.list.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <li key={id}>
                      <Link
                        href={href}
                        className="hover:text-accent dark:hover:text-white">
                        {text}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
          <span className="dark:text-jakarta-400 text-sm">
            <span>©2023 Accesspoint — Powered by </span><span className="font-display text-blue-gray-800">Intellichain</span>
          </span>
          <ul className="dark:text-jakarta-400 flex flex-wrap space-x-4 text-sm">
            <li>
              <Link href="#" className="hover:text-accent dark:hover:text-white">
                Terms and conditions
              </Link>
            </li>
            <li>
              <Link href="#" className="hover:text-accent dark:hover:text-white">
                Privacy policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </>;
};

export default footer;
