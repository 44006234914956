import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import BidsModal from "./modal/bidsModal";
import BuyModal from "./modal/buyModal";
import Header from "./header/Header";
import { useEffect, useRef, useState } from "react";
import globalState from "data/state";

export default function Layout({ children }) {
  const scrollArea = useRef<any>()
  const [top, setTop] = useState(0);
  const onScroll = (e: any) => {
    if (!scrollArea.current) return
    setTop(scrollArea.current.scrollTop)
    globalState.top = e.target.scrollTop
  }
  useEffect(() => void onScroll({ target: scrollArea.current }), [])

  return (
    <div
      ref={scrollArea}
      onScroll={onScroll}
      className="scrollArea"
    >
      <Header onScroll={top} />
      <Wallet_modal />
      <BidsModal />
      <BuyModal />
      <main>{children}</main>
      <Footer />
    </div>
  );
}