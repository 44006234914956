import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

const DarkMode = ({ isScroll }) => {
    const { theme, setTheme } = useTheme();
    const [isDarkMode, setDarkMode] = useState(false);

    useEffect(() => {
    //    console.log(theme)
    },[])

    useEffect(() => {
        setDarkMode(theme === "dark");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theme])

    const toggle = () => {
        if (theme === "light") setTheme("dark");
        else setTheme("light");
    };

    return (
        <div className={`p-1 rounded-full ${isScroll ? "bg-transparent" : "bg-[#3ab489]"} transition-all duration-100`}>
            <DarkModeSwitch
                checked={isDarkMode}
                onChange={toggle}
                size={25}
            />
        </div>
    );
}

export default DarkMode;