import globalState from "data/state";
import { useTheme } from "next-themes";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import DarkMode from '../mode/Darkmode'
import { useRouter } from "next/router";
const Header = ({ onScroll }) => {

  const [isScroll, setScroll] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);
  const [pid, setPid] = useState(null);
  const { theme } = useTheme()
  const router = useRouter();

  useEffect(() => {
    setPid(router.asPath);
  }, [router]);


  // sticky menu
  useEffect(() => {
    if (onScroll > 4) {
      setScroll(true);
    } else {
      setScroll(false);
    }

  }, [onScroll]);

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  // mobile page collapse
  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  return (
    <>
      <header className={`fixed top-0 
      ${isScroll ? "dark:bg-black bg-white shadow-xl dark:shadow-none" : pid === '/' ? "bg-gradient-to-b from-black to-transparent shadow-none" : "bg-black"} 
      w-full z-30
      transition-all duration-100 ease-in
      flex items-center justify-between
      `}
      >
        <div className="flex items-center justify-start px-8 py-6 xl:px-32">
          <Link className="flex justify-center ml-6 shrink-0" href="/">
            <Image
              className="hidden xl:block"
              src={`/images/logo${(theme === 'dark' || !isScroll) ? "-dark" : ""}.svg`}
              height={50}
              width={50}
              alt="Access Point"
            />
            <p className={`font-display text-xl ${!isScroll ? "text-white" : " text-black dark:text-white"}
            xl:ml-3`}>Accesspoint</p>
          </Link>
          {/* End  logo */}
        </div>
        <div className="flex items-center px-8 space-x-8 xl:px-32">
          {pid !== '/login' && <Link href="/admin" className={theme === 'dark' ? "text-white" : isScroll ? "text-black" : "text-white"}>Login</Link>}
          <DarkMode isScroll={isScroll} />
        </div>
      </header>
    </>
  );
}

export default Header;